import { ApiCrud } from 'adready-api/api/api-crud';
import http from 'adready-api/api/http';

const mediaPublisherApi = new ApiCrud(http.AXIOS, {
  mediaPublisher: 'GET mediaPublisher',
  createPublisher: 'POST mediaPublisher',
  updatePublisher: 'PUT mediaPublisher/:id',
  deletePublisher: 'DELETE mediaPublisher/:id',
  getMediaPublisherById: 'GET mediaPublisher/:id',
});

export default mediaPublisherApi;
