import _ from 'underscore';

import { Errors } from 'adready-api/model/errors';

import masterDataLoaders from './forklift/master-data-loaders';
import accountLoaders from './forklift/account-loaders';

// const computed = {
//   // TODO: this might not be necessary. maybe better to just define the getters wherever they are actually needed?
//   // create getter for all keys in options obj
//   // (see store/io/index.js for ref)
//   ...get('io/options@*'),
// };

const methods = {
  forkliftErrHandler(e, key) {
    if (window.$sentry) {
      if (e._reported !== true) {
        window.$sentry.captureException(e);
        e._reported = true;
      }
    }
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(key, e);
    }
    if (this.errors) {
      if (Array.isArray(this.errors)) {
        this.errors.push(e);
      } else if (this.errors instanceof Errors) {
        this.errors.update(key, e);
      }
    }
  },

  ...masterDataLoaders,
  ...accountLoaders,
};

const forklift = {
  // computed,
  methods,
};

/**
 * Create a mixin which stocks (loads) the given list of data.
 *
 * @param  {...any} opts List of master data to load
 */
export function stock(...opts) {
  if (_.isEmpty(opts)) {
    return {};
  }
  const mixin = {
    asyncComputed: {},
  };

  opts.forEach((opt) => {
    // create asyncComputed so we can always return a default value
    // while the data is being fetched
    mixin.asyncComputed[opt] = {
      default: [],
      async get() {
        const loader = `load${opt[0].toUpperCase()}${opt.slice(1)}`;
        const fn = this[loader];
        if (!fn) {
          // warn
          console.warn(`couldn't find loader method ${loader}`);
          return Promise.reject(new Error(`couldn't find loader method ${loader}`));
        }
        return fn(this.currentAccountId);
      },
    };
  });

  return { ...forklift, ...mixin };
}

export default forklift;
